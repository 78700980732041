.team__container {
    flex-direction: column;
    padding: 2rem;
}

.team__heading {
    font-size: 1.8rem;
}

.team_cards {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 0 10rem;
    overflow: hidden;
}

@media screen and (min-width: 728px) {
    .team_cards {
        flex-direction: row;
        flex-wrap: wrap;
        margin: 2rem;
    }
}

@media screen and (min-width: 1025px) {
    .team_cards {
        flex-direction: row;
        flex-wrap: nowrap;
    }
    
    .team__heading {
        font-size: 3rem;
    }

    .team__container {
        padding: 10rem;
    }
}