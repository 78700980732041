.discover__text,
.iditium__paragraph,
.background > h2,
.iditium__featured {
  display: inherit;
}

/* HERO */
.header {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  padding-bottom: 1rem;
}

.background {
  background-image: url("../../assets/hero.png");
  background-size: cover;
  background-position: center;
  box-shadow: none;
  height: 100vh;

  /* ADDED */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: left;
  padding-left: 2rem;
}

@media screen and (min-width: 768px) {
  .background {
    box-shadow: 10px 0 30px -5px var(--colour-primary) inset;
    padding-left: 10rem;
  }
}

/* NEW */
.discover__text {
  color: var(--colour-primary);
  text-transform: uppercase;
  margin: 7rem 0 5rem;
}

.discover__block {
  background-color: #0e4d64;
  margin-right: 0.2rem;
}

.iditium__title {
  line-height: 1;
  font-size: 3rem;
}

.iditium__paragraph {
  font-size: 1.5rem;
  color: var(--white);
}

.btn__spacing {
  margin-right: 1rem;
  padding: 1rem;
}

.iditium__featured {
  display: flex;
  flex-direction: column;
  position: absolute;
  bottom: 0;
  padding-top: 10rem;
  text-transform: uppercase;
}

.icon__dribble {
  width: 80px;
  height: 30px;
  margin-right: 2rem;
  /* margin: 1rem; */
  margin-left: 0;
}

.icon__wordpress {
  width: 120px;
  height: 25px;
  margin-top: 0.4rem;
  margin-left: 0;
}

@media screen and (min-width: 768px) {
  .iditium__title {
    font-size: 7.3rem;
  }

  .background > h2 {
    line-height: 3.5rem;
    font-size: 4rem;
  }
}

.software__container {
  padding: 2rem;
  overflow: hidden;
}

.column__1 {
  margin: auto;
  padding: 2rem;
}

.column__2 {
  padding: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.column__2 > h3 {
  font-size: 2.5rem;
}

.column__2 > h2 {
  font-size: 3.5rem;
}

.black__text {
  color: var(--colour-secondary);
}

.information__img {
  width: 250px;
  height: 250px;
}

@media screen and (min-width: 768px) {
  .software__container {
    padding: 6rem;
  }

  .column__1 {
    padding: 7rem;
  }

  .column__2 {
    padding: 5rem;
  }

  .information__img {
    width: 450px;
    height: 450px;
  }
}

/* Features */
.web__development__container {
  padding: 2rem;
}

.feature__heading {
  font-size: 3.5rem;
  color: #36a0c8;
}

.feature__paragraph {
  font-size: 1.6rem;
  color: rgb(161, 161, 161);
}

.feature__number {
  display: none;
}

.feature__img {
  max-width: 80%;
  max-height: 80%;
}

/* Reversed Feature  */
@media screen and (min-width: 728px) {
  .btn__right {
    margin-left: auto;
  }

  .text__right {
    text-align: right;
  }

  .row__reverse {
    flex-direction: row-reverse;
  }
}

@media screen and (min-width: 1025px) {
  .btn__right {
    margin-left: auto;
  }

  .feature__number {
    display: block;
    font-size: 10.6rem;
    line-height: 0.211;
    margin-top: 7.5rem;
    margin-right: 1.5rem;
    margin-left: 1rem;
  }

  .web__development__container {
    padding: 0 10rem;
  }
}

.padding_top {
  padding-top: 2rem;
}

.iditium__container {
  padding: 2rem;
  padding-left: 1rem;
  align-items: center;
}

.select__container {
  padding: 3rem;
}

.card__container {
  display: flex;
  justify-content: center;
  flex-direction: row;
  flex-wrap: wrap;
}

@media screen and (min-width: 1025px) {
  .iditium__container {
    padding: 10rem;
  }
}

/* Client */
.clients__container {
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(3, 1fr);
  grid-column-gap: 5px;
  grid-row-gap: 5px;
}

.client__center {
  text-align: center;
}

@media screen and (min-width: 1025px) {
  .client__center {
    margin: auto;
    padding: 1rem;
    text-align: left;
  }
}

/* Testimonal */
.testimonials {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}
