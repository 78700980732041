.list {
  list-style: none;
  padding-left: 0;
}

.icon {
  width: 40px;
  height: 40px;
}

.icon__white {
  fill: var(--white);
}

.social__icon {
  width: 30px;
  height: 30px;
  margin-right: 0.5rem;
  cursor: pointer;
}

.social__icon:hover {
  transform: scale(1.2);
}

.row {
  display: flex;
}

.grid {
  display: grid;
}

.reverse__layout {
  order: 1;
}

.text__center {
  text-align: center;
}

.overflow__hidden {
  overflow: hidden;
}

.blue__background {
  background-color: #fff2ed;
}

.center__content {
  display: flex;
  justify-content: center;
  align-items: center;
}

.column {
  display: flex;
  flex-direction: column;
}

.padding__container {
  padding: 2rem;
}

@media screen and (min-width: 768px) {
  .padding__container {
    padding: 10rem;
  }

  .padding__text {
    padding: 0 10rem;
  }
}

@media only screen and (min-width: 1025px) {
  .grid__1x2 {
    grid-template-columns: repeat(2, 1fr);
  }

  .grid__columns {
    grid-template-columns: 40% 60%;
  }

  .ellipse {
    position: absolute;
    width: 40px;
    height: 40px;
    background-color: var(--colour-primary);
  }

  .reverse__layout {
    order: -1;
  }
}

.collapsible__content {
  max-height: 0;
  overflow: hidden;
  opacity: 0;
  transition: all 0.3s;
}

.collapsible__expanded .collapsible__content {
  max-height: 100%;
  opacity: 1;
}