:root {
  --colour-primary: #00b5f8;
  --colour-secondary: #000000;
  --colour-heading: #a4a4a4;
  --colour-body: #a8a8a8;
  --white: #ffffff;
  /* 2f2a29 */
}

/* Blues: 
  Primary: 00b4f8
  Light: 2fc3fc
  Dark: 006084
  Light light: 59CFFC
*/

*,
*::before,
*::after {
  box-sizing: border-box;
}

/* Topography */
::selection {
  background: var(--colour-primary);
  color: var(--white);
}

html {
  font-size: 62.5%;
}

body {
  font-family: Montserrat, Inter, Arial, Helvetica, sans-serif;
  font-size: 1.7rem;
  font-weight: 500;
  line-height: 1.5;
  color: var(--colour-body);
}

h1,
h2,
h3 {
  margin: 0;
}

h1 {
  font-size: 6.3rem;
  font-family: Helvetica, Arial, sans-serif;
  font-weight: bold;
  color: var(--white);
}

h2 {
  font-size: 3.5rem;
  font-family: Helvetica, Arial, sans-serif;
  font-weight: bold;
  color: var(--colour-primary);
}

h3 {
  font-size: 3rem;
  color: var(--colour-heading);
}

p {
  margin-top: 0;
}

a {
  text-decoration: none;
}

@media screen and (min-width: 1024px) {
  body {
    font-size: 1.9rem;
  }

  h1 {
    font-size: 7.3rem;
  }

  h2 {
    font-size: 4rem;
  }

  h3 {
    font-size: 3.6rem;
    font-weight: 500;
  }
}
