.logo {
    width: 8rem;
    height: 8rem;
    margin-left: 8rem;
    margin-right: 5rem;
}

.logo__container {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: var(--colour-secondary);
}

.logo__icon {
    width: 10rem;
    height: 10rem;
}

@media screen and (min-width: 768px) {
    .logo__container {
        flex-direction: row;
        justify-content: space-evenly;
    }

    .logo {
        width: 10rem;
        height: 10rem;
    }
}

/* Client Logo */
.client__logo {
    width: 100%;
    height: 100%;
    padding: 1rem;
}
