.header {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  padding-bottom: 1rem;
}

.background {
  background-image: url("../../assets/hero.png");
  background-size: cover;
  background-position: center;
  box-shadow: none;
  height: 100vh;
}

@media screen and (min-width: 768px) {
  .background {
    height: 100vh;
    box-shadow: 10px 0 30px -5px var(--colour-primary) inset;
  }
}
