.feature__benefit {
  border-left: 5px solid rgba(84, 84, 84, 0.22);
  padding-left: 1rem;
}

.feature__benefit > h4,
.feature__benefit__reversed > h4 {
  font-size: 1.8rem;
  color: var(--colour-secondary);
  margin-bottom: 0.5rem;
}

.feature__text {
  font-size: 1.6rem;
  color: rgb(182, 182, 182);
}

.feature__benefit__reversed {
  border-left: 5px solid rgba(84, 84, 84, 0.22);
  padding-left: 1rem;
}

@media screen and (min-width: 768px) {
  .feature__benefit__reversed {
    border: 0;
    border-right: 5px solid rgba(84, 84, 84, 0.22);
    padding-right: 1rem;
  }
}
