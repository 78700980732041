.development__container {
  height: 100%;
  background-image: url("../../assets/software-development-bg.png");
  overflow: hidden;
}

.development__info {
  margin: 2rem 5rem;
}

.development__heading {
  font-weight: bold;
  font-size: 4rem;
  color: var(--colour-primary);
  margin: 0;
}

.development__software__header {
  font-weight: bold;
  font-size: 2.6rem;
  color: var(--white);
  margin: 0;
}

.development__text {
  font-size: 1.5rem;
  color: var(--white);
}

@media screen and (min-width: 728px) {
  .development__info {
    display: flex;
    flex-direction: column;
    margin: 5rem;
  }
}

@media screen and (min-width: 1025px) {
  .development__container {
    align-items: center;
  }

  .development__info {
    display: block;
  }

  .development__heading {
    font-size: 8rem;
  }

  .development__software__header {
    font-size: 4.6rem;
  }

  .development__text {
    font-size: 1.8rem;
  }
}
