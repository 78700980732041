.team__card {
  border-radius: 2rem;
  padding: 3rem;
  margin: 2rem;
  overflow: hidden;
  position: relative;

  box-shadow: 15px 15px 15px rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.4);
  -moz-box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.4);
}

.team__card__img {
  width: 20.7rem;
  height: 20.7rem;
  border-radius: 10rem;
  border: 5px solid #2fc3fc;
}

.card__header {
  font-size: 1.8rem;
}

.card__icons {
  display: flex;
  justify-content: center;
  align-items: center;
}

@media screen and (min-width: 1025px) {
  .card {
    margin: 3rem;
  }

  .card__header {
    font-size: 3rem;
  }
}

.card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  box-shadow: 15px 15px 15px rgba(0, 0, 0, 0.2);
  position: relative;
  width: 300px;
  height: 300px;
  overflow: hidden;

  padding: 1rem;
  margin: 1rem;
  border-radius: 20px;
}

.blue__background {
  background-color: var(--colour-primary);
}

.team__ellipse {
  position: absolute;
  width: 25px;
  height: 25px;
  background-color: #2fc3fc;

  top: 0;
  left: 0;
  border-radius: 0 0 100% 0;
}

@media screen and (min-width: 728px) {
  .ellipse {
    position: absolute;
    width: 40px;
    height: 40px;
    background-color: var(--colour-primary);
  }

  .ellipse_top_left {
    top: 0;
    left: 0;
    border-radius: 0 0 100% 0;
    background-color: #2fc3fc;
  }

  .ellipse_top_right {
    top: 0;
    right: 0;
    border-radius: 0 0 0 100%;
  }

  .ellipse_bottom_left {
    bottom: 0;
    left: 0;
    border-radius: 0 100% 0 0;
  }

  .ellipse_bottom_right {
    bottom: 0;
    right: 0;
    border-radius: 100% 0 0 0;
  }
}

.card__img {
  width: 60px;
  height: 60px;
}

.card__heading {
  font-size: 2rem;
  color: var(--colour-primary);
  text-align: center;
  margin: 1rem;
}

.card__text {
  font-size: 1.4rem;
  text-align: center;
}

.card__white__text {
  color: var(--white);
}
