.faq__container {
  width: 70%;
  max-width: 1200px;
  border: 1px solid #ccc;
  border-radius: 10px;
  padding: 2rem;
  margin: 1rem;

  -webkit-box-shadow: 2px 2px 47px -10px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 2px 2px 47px -10px rgba(0, 0, 0, 0.2);
  box-shadow: 2px 2px 47px -10px rgba(0, 0, 0, 0.2);
}

.questions__container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  cursor: pointer;
}

.questions__container > h4 {
  margin: 0;
  margin-right: 0.2rem;
  margin-bottom: 1rem;
  color: var(--colour-primary);
  font-size: 1rem;
}

.faq__icon {
  width: 30px;
  height: 25px;
  cursor: pointer;
  margin-top: 0.6rem;
}

.faq__exit__icon {
  width: 20px;
  height: 20px;
  cursor: pointer;
  margin-top: 1rem;
}

.faq__paragraph {
  font-size: 1.2rem;
}

@media screen and (min-width: 1025px) {
  .questions__container > h4 {
    font-size: 2rem;
  }
}
