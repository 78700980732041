.footer {
  display: flex;
  flex-direction: row;
  background-image: url("../../../assets/footer-background.png");
  color: var(--white);
  padding: 5rem;
}

.footer__section {
  display: flex;
  flex-direction: column;
}

.footer__logo {
  width: 18rem;
  height: 7rem;
}

.footer__paragraph {
  font-size: 1.8rem;
  margin-top: 2rem;
  padding-right: 1.5rem;
}

.footer__header {
  font-size: 2.5rem;
  margin: 0;
}

.footer__section > ul {
  padding: 0;
  list-style: none;
}

.footer__item {
  display: flex;
  flex-direction: row;
  cursor: pointer;
  margin-bottom: 0.5rem;
  color: var(--white);
}

.footer__item:hover {
  color: var(--colour-primary);
}

.footer__link {
  width: 2rem;
  height: 2rem;
  margin-right: 1rem;
  margin-top: 0.3rem;
}

.footer__icons a > svg {
  margin: 0.5rem;
}

.footer__icon {
  width: 40px;
  height: 40px;
  margin-left: auto;
  fill: var(--white);
}

.collapsible__expanded .collapsible__content {
  max-height: 100%;
  opacity: 1;
  display: block;
}

.collapsible__content {
  max-height: 0;
  overflow: hidden;
  opacity: 0;
  transition: all 0.3s;
}

.collapsible__chevron {
  transform: rotate(-90deg);
  transition: transform 0.3s;
}

.collapsible__expanded .collapsible__chevron {
  transform: rotate(0);
}

@media screen and (min-width: 1025px) {
  .grid__1x4 {
    grid-template-columns: 2fr repeat(3, 1fr);
  }

  .footer__paragraph {
    margin: 0;
  }

  .footer__icon {
    display: none;
  }

  .footer {
    background-image: url("../../../assets/footer-background-web.png");
  }

  .footer .collapsible__chevron {
    display: none;
  }

  .footer .collapsible__content {
    opacity: 1;
    max-height: 100%;
  }
}
