.nav {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 2rem;
}

.nav__list {
  width: 100%;
  margin: 2rem 0 0;
}

.nav__item {
  padding: 0.8rem 2rem;
  color: var(--white);
  text-decoration: none;
  transition: color 0.3s;
}

.nav__item:last-of-type {
  border-bottom: none;
}

.nav__item:hover {
  color: var(--colour-primary);
  transition: color 0.3s;
}

.nav__toggler {
  opacity: 0.5;
  transition: box-shadow 0.15s;
  cursor: pointer;
  margin-top: 3.5rem;
  margin-right: 2rem;
}

.collapsible__expanded .collapsible__content {
  max-height: 100%;
  opacity: 1;
  z-index: 99;
}

.collapsible__expanded .nav__toggler {
  display: block;
  opacity: 1;
  box-shadow: 0 0 0 3px #666;
  border-radius: 5px;
}

.nav__brand {
  padding-left: 2rem;
  transform: translateY(5px);
  width: 150px;
  margin-top: 2.3rem;
}

.contact__button {
  display: none;
  /* margin: 2rem 2rem 1rem 2rem; */
}

@media screen and (min-width: 728px) {
  .contact__button {
    display: block;
    margin: 2rem 2rem 1rem 2rem;
  }
}

@media screen and (min-width: 1025px) {
  .nav {
    padding-left: 6rem;
  }

  .nav__toggler {
    display: none;
  }

  .nav__brand {
    padding-left: 5rem;
  }

  .nav__list {
    width: auto;
    display: flex;
    font-size: 1.6rem;
    max-height: 100%;
    opacity: 1;
  }

  .nav__item {
    padding: 2.5rem 2rem;
    border: 0;
  }

  .nav__item:last-of-type {
    margin-right: 25rem;
  }

  .contact__button {
    display: block;
    margin: 1.2rem 5rem 0.5rem 0;
  }
}
