.contact__container {
  margin: 2rem;
  max-width: 100%;
  height: 100%;
}

.contact__information {
  background-color: var(--colour-primary);
  padding: 3rem;
}

.contact__heading {
  font-size: 2rem;
  font-weight: bold;
  color: var(--colour-secondary);
}

.contact__company {
  display: none;
}

.contact__text {
  color: var(--white);
  padding: 0.2rem;
}

@media screen and (min-width: 1025px) {
  .contact__container {
    margin: 10rem;
  }

  .contact__company {
    display: block;
  }
}

.questions__container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 5rem 2rem;
}

.questions__img {
  margin: 5rem;
}

.questions__form {
  display: flex;
  flex-direction: column;
  margin: 2rem;
}

.contact__img {
  width: 100%;
  height: 100%;
}

.contact__input {
  background-color: #f0f0f0;
  margin: 1rem;
  padding: 1rem 2rem;
  width: 90%;
  border-radius: 50px;
  border: 0;
}

.contact__textarea {
  height: 100px;
  border-radius: 30px;
  font-family: Helvetica, Arial, sans-serif;
}

.contact__button {
  margin: 1rem;
}

@media screen and (min-width: 1025px) {
  .questions__container {
    margin: 5rem 12rem;
  }

  .questions__form {
    margin: 5rem;
  }
}

::placeholder, textarea::placeholder {
  color: #9f9f9f;
  opacity: 1;
}

::-ms-input-placeholder, textarea::-ms-input-placeholder {
  color: #9f9f9f;
}

.contact__input:focus {
  outline: none;
  border: 3px solid var(--colour-primary);
}

.error__text {
  color: var(--colour-primary)
}
