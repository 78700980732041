.grid__column__1 {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  flex-wrap: wrap;
  padding: 2rem;
}

.grid__column__2 {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5vh 2rem 2rem;
}

@media screen and (min-width: 1025px) {
  .grid__column__1 {
    align-items: normal;
    padding: 7rem 0 7rem 10rem;
  }

  .grid__column__2 {
    padding: 10vh 2rem 2rem;
  }
}

.industry__container {
  overflow: hidden;
  padding: 1rem;
  height: 100%;
  background-image: url("../../assets/RoundedRectangle.png");
}

.img {
  max-width: 80%;
  max-height: 80%;
}

.industry__center {
  text-align: center;
}

@media screen and (min-width: 728px) {
  .industry__img {
    margin-top: 20rem;
  }
}

@media screen and (min-width: 1025px) {
  .industry__container {
    padding: 10rem;
  }

  .industry__center {
    text-align: left;
  }
}

.industry__column {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 10rem;
}
