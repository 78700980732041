.btn {
  border-radius: 50px;
  cursor: pointer;
  outline: none;
  box-shadow: none;
  padding: 1.5rem 2rem;
  font-size: 1.5rem;
  text-align: center;
  text-transform: uppercase;
  white-space: nowrap;
  border-color: var(--colour-primary);
  border-style: solid;
  width: 15rem;
}

.btn:hover {
  transform: scale(1.08);
}

.max__width {
  width: 90%;
}

.headerButton {
  background-color: transparent;
  color: var(--colour-primary);
}

.primaryButton {
  background-color: var(--colour-primary);
  color: var(--white);
}

.secondaryButton {
  background-color: var(--colour-secondary);
  color: var(--white);
  border-color: var(--colour-secondary);
}

.featureButton {
  background-color: #36a0c8;
  color: var(--white);
  background-clip: padding-box;
  border-color: #36a0c8;
}

.headerButton:hover {
  background-color: var(--colour-primary);
  color: var(--white);
}

.primaryButton:hover {
  background-color: #2fc3fc;
  color: var(--white);
}

@media screen and (min-width: 768px) {
  .btn {
    margin: 1rem 1rem 1rem 0;
  }
}
