.information__container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.information__overlay {
  margin: 1rem 3rem;
  border-radius: 30px;
  padding: 2rem;
  background-color: var(--white);
}

.btn__spacing {
  margin-bottom: 1rem;
}

.img {
  max-width: 100%;
  max-height: 100%;
}

.information__image {
  display: none;
}

@media screen and (min-width: 728px) {
  .information__image {
    display: none;
  }
}

@media screen and (min-width: 1025px) {
  .information__container {
    height: 70%;
    position: relative;
  }

  .information__image {
    display: block;
  }

  .information__overlay {
    margin: 1rem 4rem;
    padding: 5rem;
    position: absolute;
    top: 50%;
    left: 40%;
    transform: translate(-50%, -50%);
    /* border-left: 3px solid var(--colour-primary); */
    border: 3px solid var(--colour-primary);

    box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.4);
    -webkit-box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.4);
    -moz-box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.4);
  }

  .information__image {
    margin: 0;
    margin-left: auto;
    width: 50%;
  }

  .btn__spacing {
    margin-left: 2rem;
  }

  .ellipse_top_left {
    top: 0;
    left: 0;
    border-radius: 50% 0 100% 0;
  }

  .ellipse_bottom_right {
    bottom: 0;
    right: 0;
    border-radius: 100% 0 50% 0;
  }
}
