.testimonial__container {
  grid-template-rows: 1fr;
  box-shadow: 2px 2px 47px -10px rgba(0, 0, 0, 0.2);
  width: 100%;
  height: 100%;
  margin-bottom: 3rem;

  position: relative;
}

.testimonial__image__container {
  /* display: block; */
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 250px;
  padding: 1.5rem;
}

.testimonial__image {
  border-radius: 50%;
  max-width: 100%;
  height: 100%;
}

.testimonial__information {
  padding: 1rem;
  text-align: center;
}

.testimonial__heading {
  font-size: 1.5rem;
  margin: 0;
  margin-bottom: 0.5rem;
}

.testimonial__text {
  font-size: 1.2rem;
  margin: 0;
  margin-bottom: 1rem;
}

.testimonial__name {
  font-weight: bold;
  margin: 0;
}

.testimonial__job__title {
  font-size: 1.5rem;
  margin: 0;
  margin-bottom: 0.5rem;
}

.testimonial__apostrophe {
  height: 25px;
  width: 20px;
  padding: 0.2rem;

  position: absolute;
  bottom: -15px;
  right: 15px;
}

.apostrophe__icon {
  width: 50px;
  height: 40px;
}

.testimonial__apostrophe {
  display: none;
}

.red__text__color {
  color: var(--colour-primary);
}

.black__text__color {
  color: var(--colour-secondary);
}

.blue_background {
  background-color: var(--colour-primary);
}

.black__background {
  background-color: var(--colour-secondary);
}

.red__border {
  border: 4px solid var(--colour-primary);
}

.black__border {
  border: 4px solid var(--colour-secondary);
}

@media screen and (min-width: 1025px) {
  .testimonial__container {
    width: 600px;
    height: 300px;
    margin-right: 3rem;
    grid-template-columns: 1fr 2fr;
  }

  .testimonial__image__container {
    height: 300px;
    padding: 0;
  }

  .testimonial__image {
    border-radius: 0;
    border: none;
  }

  .testimonial__heading {
    font-size: 2.5rem;
    margin: 0;
    margin-bottom: 0.5rem;
  }

  .testimonial__information {
    padding: 1rem;
    text-align: left;
  }

  .testimonial__text {
    font-size: 1.8rem;
    margin: 0;
    margin-bottom: 5.5rem;
  }

  .testimonial__job__title {
    font-size: 1.5rem;
    margin: 0;
  }

  .testimonial__apostrophe {
    width: 80px;
    height: 45px;
    display: flex;
    justify-content: center;
  }
}
